import React from 'react'
import './nav.css'
import {AiOutlineHome} from 'react-icons/ai'
import {AiOutlineMail} from 'react-icons/ai'
import {FaRecycle} from 'react-icons/fa'
import {MdOutlineAnnouncement} from 'react-icons/md'
import {useState} from 'react'
const Nav = () => {
  const [activeNav, setActiveNav] = useState('#')
  return (
    <nav>
     <a href="#header" onClick={() => setActiveNav('#header')} className={activeNav === '#header' ? 'active' : ''}><AiOutlineHome/></a>
     <a href="#anunturi" onClick={() => setActiveNav('#anunturi')} className={activeNav === '#anunturi' ? 'active' : ''}><MdOutlineAnnouncement/></a>
     <a href="#reciclare" onClick={() => setActiveNav('#reciclare')} className={activeNav === '#reciclare' ? 'active' : ''}><FaRecycle/></a>
     <a href="#contact" onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}><AiOutlineMail/></a>
     

    </nav>
  )
}

export default Nav