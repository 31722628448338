import React from 'react'
import './about.css'

import slide1 from "../../assets/slide1.jpg"
import slide2 from "../../assets/slide2.jpg"


import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'



function About() {
  return (
    <section id='#about'>
    <div className="container about__container">
    <Swiper
      spaceBetween={30}
      slidesPerView={1}
      centeredSlides
      onSlideChange={() => console.log("slide change")}
      onSwiper={swiper => console.log(swiper)}
    >
      <SwiperSlide><img src={slide2} alt="1" /></SwiperSlide>
      <SwiperSlide><img src={slide1} alt="2" /></SwiperSlide>
      
    </Swiper>
    <h2>Cu peste 38 de ani de activitate in domeniul reciclării, avem ca obiectiv colectarea și prelucrarea deșeurilor feroase și neferoase conform standardelor și normelor impuse de legislația in vigoare. Activitatea noastră se desfășoară intr-un mod complet profesional, folosind echipamente și tehnologii de ultimă generație.</h2>
    

    </div>
    </section>
  )
}

export default About