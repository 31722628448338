import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md' 
import {MdLocationOn} from 'react-icons/md'
import {AiOutlineWhatsApp} from 'react-icons/ai'
import { useRef } from 'react';
import emailjs from 'emailjs-com'

function Contact() {
  const form=useRef() ;
  

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_yq4bcel', 'template_llejhhr', form.current, 'tW7jv7r1fMWxqhHbW')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
    e.target.reset()
  };

  return (
    <section id='contact'>
    <h2>Completați formularul de mai jos pentru a primi o ofertă personalizată. Atenție, S.C. REMAT CONSTANȚA S.A. primește deșeuri doar de la persoane juridice autorizate și de la persoane juridice generatoare de deșeuri.</h2>

    <div className='container contact__container'>
    <div className='contact__options'>
      <a href="mailto:office@remat-constanta.ro" target="_blank" rel="noopener noreferrer"><article className='contact__option'>
        <MdOutlineEmail className='contact__option-icon' />
       <h4>Email</h4>
       <h5>office@remat-constanta.ro</h5>
       





      </article></a>
      <a href="https://www.google.com/maps/place/Remat+Constan%C5%A3a+S.A./@44.1788736,28.6053853,17z/data=!4m5!3m4!1s0x40baef8a9f636855:0xf271c9204b728c23!8m2!3d44.1788736!4d28.6053853" target="_blank" rel="noopener noreferrer"><article className='contact__option'>
        <MdLocationOn className='contact__option-icon'/>
       <h4>Locație</h4>
       <h5>Strada Interioară nr. 2 Constanța, judetul Constanța</h5>
       <h5>J13/580/1991 CIF RO 1872253</h5>
       





      </article></a>
      <a href="https://wa.me/+40722300013" target="_blank" rel="noopener noreferrer"><article className='contact__option'>
        <AiOutlineWhatsApp className='contact__option-icon'/>
       <h4>Telefon</h4>
       <h5>0722300013</h5>
      





      </article></a>





    </div>


   <form ref={form} onSubmit={sendEmail}>
     <input type="text" name='name' placeholder="Nume" required />
     <input type ="email" name='email' placeholder='Email' required />
     <textarea name="message" rows="10" placeholder="Tipul deșeului și cantitatea" required></textarea>
<button type='submit' className='btn btn-primary'>Trimite Mesaj</button>


   </form>


    </div>



    </section>
  )
}

export default Contact