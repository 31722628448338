import React from 'react'
import './portofolio.css'
import IMG1 from "../../assets/neferoase.jpg"
import IMG2 from "../../assets/feroase.jpg"
import IMG3 from "../../assets/baterii.jpg"


const Portofolio = () => {
  return (
    <section id='reciclare'>
    <h2>S.C REMAT CONSTANȚA S.A reciclează o gamă variată de materiale.</h2>
    <div className='container portofolio__container'>
    <article className="portofolio__item">
      <div className="portofolio__item-image">
        <img src={IMG1} alt="" />
        

      </div>
      <h5>MATERIALE NEFEROASE</h5> 
         
      <ol>
      <li className='text-center'> - Cupru</li>
            <li className='text-center'> - Aluminiu</li>
            <li className='text-center'> - Alamă</li>
            <li className='text-center'> - Bronz</li>
            <li className='text-center'> - Plumb</li>
        </ol>

    </article>
    <article className="portofolio__item">
      <div className="portofolio__item-image">
        <img src={IMG2} alt="" />

      </div>
      <h5>MATERIALE FEROASE</h5>
      <ul>
            <li className='text-center'> - Fier vechi pregătit/nepregătit</li>
            <li className='text-center'> - Șpan fier</li>
            <li className='text-center'> - Inox</li>
            
            </ul>     


    </article>
    <article className="portofolio__item">
      <div className="portofolio__item-image">
        <img src={IMG3} alt="" />

      </div>
      <h5>BATERII/ACUMULATORI</h5>   
      <ul>
            <li className='text-center'> - Baterii auto , cu plumb acid, nichel-cadmiu</li>
            <li className='text-center'> - Baterii industriale , cu plumb acid, nichel-cadmiu</li>
            
            </ul>  


    </article>


    </div>

    </section>
  )
}

export default Portofolio