import React from 'react'
import './experience.css'


function experience() {
  return (
    <section id='anunturi'>
    <h2>Anunțuri</h2>
    
    
    <div className='container experience__container'>
   
    <div className='experience__frontend'>
      <h3>CONVOCATOR</h3>
      <div className='experience_content'>
<p>Administratorul Unic al REMAT CONSTANTA S.A., in conformitate cu Legea 31/1990, convoaca pentru
data de 26.04.2024, ora 10:00 A.G.O.A pentru toti actionarii mentionati in Registrul Actionarilor conform evidentei de la
DEPOZITARUL CENTRAL S.A. inregistrati la sfarsitul zilei de 31.03.2024 la sediul METALROM S.R.L,Loc. Bucuresti
Sector1,str. Alexandru Constantinescu nr.22, cladirea C3,Et. 1, Cam. 2 cu urmatoarea ordine de zi:    
           <br />1)Prezentarea Raportului de gestiune al administratorului unic al societatii pe anul 2022 si analiza activitatii
societatii;
           <br />2)Prezentarea Raportului auditorului societatii privind activitatea pe anul 2023;
           <br />3)Aprobarea bilantului contabil, a contului de profit/pierdere,repartizarea profitului;
           <br />4)Aprobarea bugetului de venituri si cheltuieli pe anul 2024;
           <br />5)Diverse
           
           Daca A.G.O.A. nu se poate intruni din cauza neindeplinirii conditiilor legale, a doua convocare este pentru data de
29.04.2023, la aceeasi ora , in acelasi loc.
<br /><br /><br />Administrator Unic, Nicoleta Daniela Glovaci.

</p>
      </div>
    </div>
    <div className='experience_backend'>
      <h3>ATENȚIE!!</h3>
      <div className='experience_content'></div>
      <p>S.C. REMAT CONSTANȚA S.A. nu este implicată in programul rabla 2022.<br />Nu primim autovehicule in vederea casării sau a dezmembrării. <br /> De asemenea, nu avem in stoc piese auto, jante sau cauciucuri.<br /> Vă rugăm să vă adresați către unul dintre operatorii autorizați pentru programul rabla dacă doriți să casați un autovehicul.</p>
    </div>


    </div>



    </section>
  )
}

export default experience