import React from 'react'
import './header.css'
import CTA from './CTA'
import LOGO from '../../assets/logo.png'



const Header = () => {
  return (
    <header>
      <section id='header'>
      <div className=".container.header__container">
       <div className="logo">
         <img src={LOGO} alt="logo" />
         <CTA />
         
         
         
       </div>
       
       
       

      </div>
      </section>

    </header>
    
  )
}

export default Header